<template>
  <div class="home">
    <!-- 这是网页的头部版心盒子 -->
    <div class="header">
      <div>
        <img src="../image/logo.jpg" alt="">
      </div>
      <div class="header_search">
        搜索：<input type="text" placeholder="请输入搜索内容">
      </div>
    </div>
    <!-- 导航栏平铺部分：会显示黑色啦 -->
    <div id="nav">
      <!-- 导航栏版心部分 -->
      <div class="nav_con">
        <ul>
          <li><a href="#">公司简介</a></li>
          <li><a href="#">公司详情</a></li>
          <li><a href="#">公司新闻</a></li>
          <li><a href="#">公司经营</a></li>
        </ul>
      </div>
    </div>
    <!-- banner的平铺部分 -->
    <div id="banner">

      <div class="banner_con header_img">
        <!-- <img src="./image/banner1_02.jpg"> -->
      </div>
    </div>
    <!-- 新闻列表的版心部分 -->
    <div class="news_con">
      <!-- 最左侧的盒子 -->
      <div class="news_l">
        <h3 class="news_title">公司简介</h3>
        <ul class="news_list">
          <p>简介：济南医宠点评信息科技有限公司成立于2023年09月26日，注册地位于山东省济南市槐荫区烟台路西元大厦1-501，法定代表人为薛汇文。</p>
        </ul>

      </div>
      <!-- 中间的盒子-->
      <div class="news_c">
        <h3 class="news_title">公司经营</h3>
        <p>
          一般项目：信息技术咨询服务；组织文化艺术交流活动；文艺创作；咨询策划服务；电子产品销售；畜牧渔业饲料销售；玩具销售；计算机软硬件及辅助设备零售；计算机软硬件及辅助设备批发；软件销售；软件开发；食品销售（仅销售预包装食品）；货物进出口；企业管理；社会经济咨询服务；市场调查（不含涉外调查）；广告制作；广告发布；广告设计、代理；医院管理；宠物服务（不含动物诊疗）；企业管理咨询；旅游开发项目策划咨询；软件外包服务；信息系统运行维护服务；数据处理服务；技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广
        </p>
      </div>
      <!-- 右边的盒子 -->
      <div class="news_r">
        <h3>基本信息</h3>
        <p class="txt3">公司邮箱:cydp001@163.com</p>
        <p class="txt3">联系电话:15668357202</p>
        <p class="txt3">公司法人:薛汇文</p>

      </div>
    </div>
    <!-- 市场项目那一部分 -->
    <div class="case_con">
      <h3>公司新闻</h3>
      <div class="case_box">
        <div>
          3月1日高薪聘请了一名高级程序开发人员
        </div>
        <div>
          3月5号开展全体员工大扫除活动
        </div>
        <div>
          3月8号开展户外野餐,每个人都很开心
        </div>
        <div>
          3月12号公司内部规定调整
        </div>
      </div>
    </div>

    <!-- 产品中心那一部分的平铺-->
    <div class="case_con">
      <h3>公司产品</h3>
      <div class="case_box">
        <div>
          <div>
            应用产品
          </div>

          <div>应用名称：医宠点评</div>
          <div>
            应用图标：<img style="width: 100px;height: 100px;"
              src="https://yichong-1320557350.cos.ap-shanghai.myqcloud.com/24a471bad595a12a9cce1c5931f58ea.png" alt="">
          </div>
          <div>应用简介：专注宠物医疗和动物保护</div>
          <div>
            应用描述：<p>
              养宠家庭最实用、最有趣的APP：
              宠物医疗： 为您推荐服务优质、价格透明的医院，提供最专业最负责的医生供您选择；
              动物保护：直播流浪小动物，在您的城市举办志愿者活动，期待遇到您的流浪小天使；
              宠物档案：为您的爱宠创建健康档案，方便爱宠就医，同时通过大数据进行健康状态分析，为您提示小动物的健康风险，早发现早干预，让小家伙们更健康更开心；
              寻宠：结合了地图定位系统，让寻宠信息传播更高效；
              本地生活：为宠物主推荐身边优质平价的宠物生活服务；
              宠物社群：分享养宠生活、学习养宠知识、交流养宠经验、变身养宠达人；
            </p>
          </div>
        </div>

      </div>
    </div>
    <div class="footer">
      <div>
        备案号： <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery">鲁ICP备2023043711号-1</a>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  data() {
    return {


    };
  },
  created() {

  },
  mounted() {

  },

  methods: {

  }
};
</script>
<style>
.footer {
  width: 100%;
  height: 100px;
  background-color: rgb(149, 148, 148);
  display: flex;
  align-items: center;
  color: white;
  font-size: 14px;
  justify-content: center;
}

.footer img {
  width: 20px;
  height: 20px;

}

 
/* 统一板块宽度和左右居中 */
 *{
    margin: 0px;
    padding: 0px;
 }
 .header{
    width: 100%;
    display: flex;
    align-items: center;
    height: 80px;
    padding: 0px 30px;
    justify-content: space-between;
    box-sizing: border-box;
 }
 .header img{
    width: 200px;
    height: 50px;
    object-fit: contain;
 }
 .header_search{
    display: flex;
    align-items: center;
    font-size: 18px;

 }
 .header_search input{
    width: 200px;
    height: 30px;
    border-radius: 10px;
    padding-left: 10px;
 }
.header_con {
    width: 1002px;
    height: 100px;
    /* background: pink; */
    /* 左右居中 */
    margin: 0 auto;
}
 
.header_con h1 {
    width: 604px;
    height: 66px;
    /* background: orange; */
    float: left;
    padding: 34px 0 0 20px;
}
 
.header_con form {
    width: 227px;
    height: 61px;
    /* background: orangered; */
    float: right;
    padding-top: 39px;
    padding-right: 21px;
}
 
.header_con .search {
    width: 195px;
    height: 26px;
    background: #f1f1f1;
    border: 1px solid #e5e5e5;
    /* 清除右侧边框 */
    border-right: none;
    float: left;
    color: #888888;
}
 .header_con img{
    width: 30px;
    height: 30px;
 }
.header_con .btn {
    width: 30px;
    height: 26px;
    border: none;
    /* background: #f1f1f1 url("../image/search_03.jpg") no-repeat center; */
}
 
.header_con form div {
    width: 30px;
    height: 26px;
    border: 1px solid #e5e5e5;
    border-left: none;
    float: left;
    /* 给btn套盒子的时候也要加浮动 */
}
 
#nav {
    height: 58px;
    background: rgb(0, 0, 0);
}
 
#nav .nav_con {
    width: 1002px;
    height: 58px;
    background: black;
    margin: 0 auto;
}
 
 
/* 导航横向排列 */
 
#nav .nav_con li {
    width: 118px;
    height: 58px;
    float: left;
    background: black;
    /* 左右居中 */
    text-align: center;
    /* 上下居中 */
    line-height: 58px;
    border-left: 1px solid #4a4a4a;
    font-size: 12px;
}
 
.nav_con li a {
    color: white;
}
 
.margin-left {
    margin-left: 25px;
    border-left: none!important;
}
 
 
/* 公共样式的footer */
 
.footer_con {
    height: 82px;
    /* background: #cccccc; */
}
 
.footer_con .footer_l {
    height: 58px;
    float: left;
    /* background: red; */
    padding-top: 24px;
}
 
.footer_con .footer_l a {
    font-size: 12px;
    float: left;
    color: #868686;
    border-right: 1px solid #868686;
    /* padding:字体和边框的距离哈 */
    padding: 0 7px;
    padding-left: 15px;
}
 
.footer_con .footer_l .footer-right {
    border-right: none!important;
}
 
.footer_con .footer_r {
    height: 57px;
    color: #8a8a8a;
    font-size: 12px;
    padding-top: 25px;
    /* background: pink; */
    padding-right: 21px;
    float: right;
}
.banner_con,
.news_con,
.case_con,
.links_con,
.footer_con {
    width: 1002px;
    margin: 0 auto;
}
 .header_img{
    width: 100%;
    height: 800px;
    background: url("https://yichongdianping.oss-cn-beijing.aliyuncs.com/uploads/20240220/108f950588342170d8bbc46a7951a094.webp") no-repeat;
    background-size: 100% 100%;
 }
#banner {
    height: 580px;
    /* background:  url("https://yichongdianping.oss-cn-beijing.aliyuncs.com/uploads/20240220/e8f3128c9a69bcb1418614c9ceec74fa.webp") no-repeat center; */
}
 
#banner .banner_con {
    height: 800px;
    /* background: pink; */
    margin: 0 auto;
}
 
.news_con {
    height: 243px;
    background: white;
}
 
.news_title {
    /* background: cadetblue; */
    font-size: 18px;
    color: #3f434f;
    line-height: 18px;
    padding-top: 36px;
}
 
.news_list {
    /* background: cornflowerblue; */
    margin-top: 23px;
    padding-right: 20px;
    color: #66676c;
}
 
.news_list li {
    width: 437px;
    height: 25px;
    /* background: url("../image/ico_05.jpg") no-repeat left; */
    padding-left: 14px;
    /* margin-top: 23px;不能给li加哦 要给他爹，不然每个儿子顶部都会空出来*/
}
 
.news_list li a {
    float: left;
    font-size: 12px;
    color: #565656;
    line-height: 25px;
}
 
.news_list li span {
    float: right;
    color: #9a9a9a;
    font-size: 12px;
}
 
.news_l {
    width: 300px;
    height: 243px;
    /* background: rebeccapurple; */
    float: left;
    padding-left: 21px;
}
 
.news_c {
    width: 300px;
    height: 243px;
    /* background: #f1f1f1; */
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;

    float: left;
    padding: 0 27px 0 20px;
}
 .news_c p{
    /* 展示5行,超出后省略号 */
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    /* 溢出部分隐藏 */
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 25px;
 }
.news_c .txt1 {
    font-size: 12px;
    color: #555555;
    line-height: 25px;
    margin-top: 34px;
    /* 行高自己去量哦 */
}
 
.news_c .txt2 {
    font-size: 12px;
    color: #979797;
    line-height: 25px;
    margin-top: 15 px;
    /* 行高自己去量哦 */
}
 
.news_r {
    width: 250px;
    height: 243px;
    /* background: #fbfbfb url("../image/ma_05.jpg") no-repeat right bottom; */
    float: left;
    padding: 0 38px 0 24px;
}
 
.news_r .txt3 {
    font-size: 12px;
    color: #585858;
    line-height: 24px;
    margin: 21px 0 22px 0;
}
 
.case_con {
    min-height: 304px;
    padding: 20px 0px;
    /* background: goldenrod; */
}
 
.case_con h3 {
    font-size: 18px;
    color: #3f434f;
    /* background: pink; */
    padding: 28px 0 18px 22px;
}
 
.case_con .case_box {
    min-height: 240px;
    /* background: orange; */
}
 
.case_box dl {
    width: 210px;
    /* 这是由图片尺寸为210得出的，这样文本就会屈居于一处 */
    /* background: burlywood; */
    float: left;
    margin: 0 20px;
}
 
 
/* 这里很重要！一定要设置宽高，也就是图片尺寸得出，这样以后图片更换，结构就不会乱！ */
 
.case_box dl dt {
    width: 210px;
    height: 136px;
}
 
.case_box dl dd {
    font-size: 12px;
    color: #4f4f4f;
    line-height: 24px;
    margin-top: 13px;
}
 
.case_box{
    display: flex;
    justify-content: center;
}
.case_box div{
    flex: 1;
    /* border: 1px solid #e5e5e5; */
    padding:0px 20px;
    color: #5d5c5c;
}
/* 上面给dt这个容器指定了宽高，现在让img跟随这个容器的宽高！！ */
 
.case_box dl dt img {
    width: 100%;
    height: 100%;
}
 
#links {
    background: #e5e5e5;
}
 
.links_con {
    height: 250px;
    /*   */
}
 
.links_con .links_title {
    color: #5d5d5d;
    line-height: 16px;
    border-bottom: 1px solid #c1c1c1;
    padding: 31px 0 11px 13px;
}
 
.links_con .links_l {
    width: 452px;
    height: 250px;
    background: #e5e5e5;
    float: left;
    margin-left: 20px;
}
 
.links_con .links_list {
    /* background: cornsilk; */
    height: 170px;
    padding-left: 5px;
    padding-top: 15px;
}
 
.links_con .links_list li {
    width: 136px;
    height: 24px;
    /* background: url("../image/btn_img_13.jpg") no-repeat left center; */
    font-size: 12px;
    line-height: 24px;
    padding-left: 13px;
    float: left;
}
 
.links_con .links_list li a {
    color: #5f5f5f;
}
 
.links_con .links_c {
    width: 153px;
    height: 250px;
    background: #e5e5e5;
    float: left;
    margin: 0 50px;
}
 
.links_con .links_c .links_list li {
    width: 130px;
}
 
.links_con .links_r {
    width: 256px;
    height: 250px;
    background: #e5e5e5;
    float: left;
}
 
.links_con .links_r .map {
    text-align: center;
    padding-top: 11px;
}

</style>
